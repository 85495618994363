import { Page } from '@components/layout'

export const NotFoundView = () => {
  return (
    <Page
      title="Not Found"
    >
      <h1>404: Not Found</h1>
    </Page>
  )
}